import React, { Component } from "react";
import { ethers } from "ethers";
import { useEffect, useLayoutEffect, useState } from "react";
import "./App.css";
import oldContract from "./Contracts/BHStaking_old.json";
import contract from "./Contracts/BHStaking.json";
import ercContr from "./Contracts/BunnyHole.json";

const oldContractAddress = "0x113A540A2CeF5D36A30D7Cc142D6d7CAc34464Cd";
const contractAddress = "0x28bdf183bAB7091B8FeAc19ae82E27A8B96eC0C3";
const erc721Address = "0x59F0C67345A62d747ca70320517F64DFAfDD88F0";
const heckelsAddress = "0x5e98f08e4C2b80474a655649cFF4eB5fAB1EA78E";

const oldAbi = oldContract.abi;
const abi = contract.abi;
const abi721 = ercContr.abi;

const App = () => {
  const [currentAccount, setCurrentAccount] = useState(null);
  const pleaseConnect =
    "<span style=\"font-size:48px\">Please use <a href='https://metamask.app.link/dapp/cryptoheck.xyz/oni/index.html'>MetaMask</a> (or another wallet) for this dApp!</span></br></br>";
  const [table, setTable] = useState(true);
  const [tokensFound, setTokensFound] = useState(false);
  const [tokensOld, setTokensOld] = useState(0);

  const [stake, setStake] = useState({ mssg: [] });
  const [unstake, setUnstake] = useState({ mssg: [] });

  const [ownes, setOwners] = useState({ massage: [] });
  const [earns, setEarn] = useState({ msg: [] });
  const [toke, setToke] = useState({ tok: [] });
  const [wallet, setWallet] = useState(0);
  const [earned, setEarned] = useState(0);
  const [staked, setStaked] = useState(0);
  const [unstaked, setUnstaked] = useState(0);
  const [approval, setApproval] = useState(0);

  const [shown, setShown] = useState(0);

  const [menu, setMenu] = useState(false);

  function resetWindow() {
    if (window.innerWidth <= 700) {
      setShown(1);
    } else {
      setShown(0);
    }
  }

  const { ethereum } = window;
  const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
  provider.on("network", (newNetwork, oldNetwork) => {
    // When a Provider makes its initial connection, it emits a "network"
    // event with a null oldNetwork along with the newNetwork. So, if the
    // oldNetwork exists, it represents a changing network
    if (oldNetwork) {
      window.location.reload();
    }
    //console.log(newNetwork);
  });
  const signer = provider.getSigner();

  //const stakeContract = new ethers.Contract(contractAddress, abi, signer);

  var g = 1;

  const checkWalletIsConnected = async () => {
    resetWindow();
    if (!ethereum) {
      document.getElementById("main-app").innerHTML = pleaseConnect;
      return;
    } else {
      document.getElementById("install").innerHTML = "";
      console.log("Wallet exists! We're ready to go!");

      var curNetwork = await provider.getNetwork();
      var netName = curNetwork.chainId;

      if (netName !== 137 && netName !== 80001) {
        document.getElementById("demo").innerHTML =
          "<div style='color:red;'> Please make sure you are connected to the Polygon Network!</div>";
      }
    }
  };

  const connectWalletHandler = async () => {
    if (!ethereum) {
      alert("Please install Metamask!");
    }

    try {
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      console.log("Found an account! Address: ", accounts[0]);
      setCurrentAccount(accounts[0]);
      getTokens();
      return true;
    } catch (err) {
      document.getElementById("demo").innerHTML =
        "<div style='color:red;'>" + err + " </div>";
      return false;
    }
  };

  const connectContainer = async () => {
    let bees = await connectWalletHandler();
  };

  const stakeNftHandler = async (_tokenID) => {
    //ClearAllIntervals();
    console.log(_tokenID);
    const stakeContract = new ethers.Contract(contractAddress, abi, signer);
    const nftContract = new ethers.Contract(erc721Address, abi721, signer);
    try {
      if (ethereum) {
        var curNetwork = await provider.getNetwork();
        var netName = curNetwork.chainId;
        if (netName == 137 || netName == 80001) {
          console.log("bun");
          var transX = await nftContract[
            "safeTransferFrom(address,address,uint256)"
          ](currentAccount, contractAddress, _tokenID);
        } else {
          document.getElementById("demo").innerHTML =
            "<div style='color:red;'> Please make sure you are connected to the Polygon Network!</div>";
        }
      } else {
        console.log("Ethereum object does not exist");
      }
    } catch (err) {
      if (err.data != null) {
        document.getElementById("demo").innerHTML = err.data.message;
      }
    }
  };

  const stakeAllHandler = async (_tokenID) => {
    //ClearAllIntervals();
    console.log(_tokenID);
    const stakeContract = new ethers.Contract(contractAddress, abi, signer);
    const nftContract = new ethers.Contract(erc721Address, abi721, signer);
    try {
      if (ethereum) {
        var curNetwork = await provider.getNetwork();
        var netName = curNetwork.chainId;
        if (netName == 137 || netName == 80001) {
          console.log("bun");
          var transZ = await nftContract.isApprovedForAll(
            currentAccount,
            contractAddress
          );
          console.log(transZ);
          if (transZ == false) {
            var transY = await nftContract.setApprovalForAll(
              contractAddress,
              true
            );
            //var transX = await stakeContract.stakeAll();
          } else {
            var transX = await stakeContract.stakeAll();
          }
        } else {
          document.getElementById("demo").innerHTML =
            "<div style='color:red;'> Please make sure you are connected to the Polygon Network!</div>";
        }
      } else {
        console.log("Ethereum object does not exist");
      }
    } catch (err) {
      if (err.data != null) {
        document.getElementById("demo").innerHTML = err.data.message;
      }
    }
  };

  const claimAllHandler = async (_tokenID) => {
    //ClearAllIntervals();
    console.log(_tokenID);
    const stakeContract = new ethers.Contract(contractAddress, abi, signer);
    try {
      if (ethereum) {
        var curNetwork = await provider.getNetwork();
        var netName = curNetwork.chainId;
        if (netName == 137 || netName == 80001) {
          console.log("bun");
          var transX = await stakeContract.receiveRewardAll();
        } else {
          document.getElementById("demo").innerHTML =
            "<div style='color:red;'> Please make sure you are connected to the Polygon Network!</div>";
        }
      } else {
        console.log("Ethereum object does not exist");
      }
    } catch (err) {
      if (err.data != null) {
        document.getElementById("demo").innerHTML = err.data.message;
      }
    }
  };

  const unstakeAllHandler = async (_tokenID) => {
    //ClearAllIntervals();
    console.log(_tokenID);
    const stakeContract = new ethers.Contract(contractAddress, abi, signer);
    const nftContract = new ethers.Contract(erc721Address, abi721, signer);
    try {
      if (ethereum) {
        var curNetwork = await provider.getNetwork();
        var netName = curNetwork.chainId;
        if (netName == 137 || netName == 80001) {
          console.log("bun");
          var transX = await stakeContract.returnTokenAll();
        } else {
          document.getElementById("demo").innerHTML =
            "<div style='color:red;'> Please make sure you are connected to the Polygon Network!</div>";
        }
      } else {
        console.log("Ethereum object does not exist");
      }
    } catch (err) {
      if (err.data != null) {
        document.getElementById("demo").innerHTML = err.data.message;
      }
    }
  };

  const oldUnstakeAllHandler = async (_tokenID) => {
    //ClearAllIntervals();
    console.log(_tokenID);
    const stakeContract = new ethers.Contract(
      oldContractAddress,
      oldAbi,
      signer
    );
    const nftContract = new ethers.Contract(erc721Address, abi721, signer);
    try {
      if (ethereum) {
        var curNetwork = await provider.getNetwork();
        var netName = curNetwork.chainId;
        if (netName == 137 || netName == 80001) {
          console.log("bun");
          var transX = await stakeContract.returnTokenAll();
        } else {
          document.getElementById("demo").innerHTML =
            "<div style='color:red;'> Please make sure you are connected to the Polygon Network!</div>";
        }
      } else {
        console.log("Ethereum object does not exist");
      }
    } catch (err) {
      if (err.data != null) {
        document.getElementById("demo").innerHTML = err.data.message;
      }
    }
  };

  const unstakeNftHandler = async (_tokenID) => {
    //ClearAllIntervals();
    const stakeContract = new ethers.Contract(contractAddress, abi, signer);
    var tokensStaked = await stakeContract.getTokensOfOwner(currentAccount);
    const nftContract = new ethers.Contract(erc721Address, abi721, signer);
    try {
      if (ethereum) {
        var curNetwork = await provider.getNetwork();
        var netName = curNetwork.chainId;
        if (netName == 137 || netName == 80001) {
          var transX = await stakeContract.returnToken(_tokenID);
        } else {
          document.getElementById("demo").innerHTML =
            "<div style='color:red;'> Please make sure you are connected to the Polygon Network!</div>";
        }
      } else {
        console.log("Ethereum object does not exist");
      }
    } catch (err) {
      if (err.data != null) {
        document.getElementById("demo").innerHTML = err.data.message;
      }
    }
  };

  const connectWalletButton = () => {
    return (
      <button
        onClick={connectContainer}
        className="cta-button connect-wallet-button"
      >
        Connect Wallet
      </button>
    );
  };

  const StakeNftButton = (props) => {
    return (
      <button
        onClick={() => stakeNftHandler(props.msg)}
        className="stake-nft-button"
      >
        Stake
      </button>
    );
  };

  function test(a) {
    console.log(a);
  }

  const TakeEarnings = (props) => {
    return <></>;
  };

  const UnstakeNftButton = (props) => {
    //let nftPrice = currentPrice;
    return (
      <>
        <button
          onClick={() => unstakeNftHandler(props.msg)}
          className="stake-nft-button unstake-nft-button"
        >
          Unstake
        </button>
      </>
    );
  };

  const UnstakeAllButton = (props) => {
    //let nftPrice = currentPrice;
    return (
      <>
        <button
          onClick={() => unstakeAllHandler(props.msg)}
          className="cta-button claim-all"
        >
          Unstake All
        </button>
      </>
    );
  };

  const MigrateButton = (props) => {
    //let nftPrice = currentPrice;
    switch (approval) {
      case 0:
        return (
          <>
            <button className="cta-button migrate-all grey">Migrate</button>
          </>
        );
        break;
      case 1:
        return (
          <>
            <button
              onClick={() => oldUnstakeAllHandler(props.msg)}
              className="cta-button migrate-all pink"
            >
              Migrate
            </button>
          </>
        );
        break;
    }
  };

  const ClaimAllButton = (props) => {
    //let nftPrice = currentPrice;
    switch (approval) {
      case 0:
        return (
          <>
            <button className="cta-button claim-all claim-em grey">
              Claim
            </button>
          </>
        );
        break;
      case 1:
        return (
          <>
            <button
              onClick={() => claimAllHandler(props.msg)}
              className="cta-button claim-all claim-em pink"
            >
              Claim
            </button>
          </>
        );
        break;
    }
  };

  const StakeAllButton = (props) => {
    //let nftPrice = currentPrice;
    switch (approval) {
      case 0:
        return (
          <>
            <button
              onClick={() => stakeAllHandler()}
              className="cta-button approve-all pink"
            >
              Approve All
            </button>
          </>
        );
        break;
      case 1:
        return (
          <>
            <button
              onClick={() => stakeAllHandler()}
              className="cta-button stake-all"
            >
              Stake All
            </button>
          </>
        );
        break;
    }
  };

  var rowSize = 4;
  var rows = [];
  var tOwner = [];
  var tEarn = [];
  var tEarnFixed = [];

  var xt = null;

  var waiting = setInterval(async function() {
    ClearAllIntervals();
    if (currentAccount != null) {
      ClearAllIntervals();
      reloadEarn();

      console.log("Whee");
    }
  }, 500);

  async function reloadEarn() {
    console.log("Buns");
    let _allow = false;
    const stakeContract = new ethers.Contract(contractAddress, abi, signer);

    const nftContract = new ethers.Contract(erc721Address, abi721, signer);
    xt = setInterval(async function() {
      //clearInterval(waiting);
      var tokensStaked = await stakeContract.getTokensOfOwner(currentAccount);
      let abib = [
        "function balanceOf(address _wallet) public view returns (uint amount)",
      ];
      let cont = new ethers.Contract(heckelsAddress, abib, signer);
      let walletHeck = await cont.balanceOf(currentAccount);
      walletHeck = parseInt(walletHeck);
      walletHeck /= 1000000000000000000;
      var walletHeckFixed = walletHeck.toFixed(4);
      console.log(walletHeck);
      var earnTotal = 0;

      var transZ = await nftContract.isApprovedForAll(
        currentAccount,
        contractAddress
      );
      console.log(transZ);
      if (transZ === false) {
        setApproval(0);
      } else {
        setApproval(1);
      }

      console.log("beep: " + approval);
      earnTotal = 0;
      var tokenAmount = tokensStaked.length;
      if (tokenAmount != tokensOld && tokensOld != -1) {
        //if (_allow != approval)
        console.log(tokensOld + ":" + tokenAmount);
        getTokens();
        clearInterval(xt);
      }
      var tRows = Math.ceil(tokenAmount / rowSize);

      for (var k = 0; k < tokenAmount; k++) {
        tEarn[k] = await stakeContract.checkWork(tokensStaked[k]);
        tEarn[k] = parseInt(tEarn[k]);
        tEarn[k] /= 1000000000000000000;
        earnTotal += tEarn[k];
        console.log(tEarn[k]);
        tEarnFixed[k] = tEarn[k].toFixed(4);
      }
      setEarn({ msg: tEarnFixed });
      earnTotal = earnTotal.toFixed(4);
      setEarned(earnTotal);
      setWallet(walletHeckFixed);

      //document.getElementById("total").innerHTML = ("Carrots to receive: " + earnTotal + " $CRRT");
      //document.getElementById("wallet").innerHTML = ("Carrots in wallet: " + walletHeckFixed + " $CRRT");
    }, 5000);
  }

  function ClearAllIntervals() {
    for (var i = 1; i < 99999; i++) {
      window.clearInterval(i);
    }
    console.log("Bums");
  }

  //var tID = [];

  const getTokens = async () => {
    if (ethereum) {
      try {
        //ClearAllIntervals();
        var _account = currentAccount;
        if (_account == null) {
          console.log("but why though?");
          const accounts = await ethereum.request({
            method: "eth_requestAccounts",
          });
          _account = accounts[0];
        } else {
          console.log("beans");
        }
        console.log("got account: " + _account);
        rows = [];
        var tID = [];
        //setStake({ mssg: rows });
        console.log(provider);
        console.log(signer);
        const stakeContract = new ethers.Contract(contractAddress, abi, signer);
        console.log("Contract instanced: ");
        console.log(stakeContract);
        var tokensStaked = await stakeContract.getTokensOfOwner(_account);
        setStaked(tokensStaked.length);
        console.log("Got staked.");
        //tID = tokensStaked;
        //console.log(tID);

        const nftContract = new ethers.Contract(erc721Address, abi721, signer);
        var tokensOwned = await nftContract.walletOfOwner(_account);
        setUnstaked(tokensOwned.length);
        setTokensOld(tokensStaked.length);
        var tokensOwned = tokensStaked.concat(tokensOwned);
        var tokenAmount = tokensOwned.length;
        console.log(tokenAmount);
        var transZ = await nftContract.isApprovedForAll(
          _account,
          contractAddress
        );
        console.log(transZ);
        if (transZ === false) {
          setApproval(0);
        } else {
          setApproval(1);
        }
        var tRows = Math.ceil(tokenAmount / rowSize);
        for (var i = 0; i < tRows; i++) {
          let columns = [];
          let _baseURI = await nftContract.baseURI();
          for (var j = 1; j < rowSize + 1; j++) {
            var k = i * rowSize + j - 1;
            console.log(k);
            let cColumn = [];
            if (k < tokenAmount) {
              tID[k] = parseInt(tokensOwned[k]);
              var tURI = _baseURI + tID[k] + ".json";
              //tID[k] = parseInt(tokensOwned[k]);
              console.log(tURI);
              var fixedURI = tURI.replace(
                "ipfs://",
                "https://yamsdev.mypinata.cloud/ipfs/"
              );
              fixedURI = fixedURI + "";
              var tJSON = Get(fixedURI);
              tJSON = tJSON.replace(
                "ipfs://",
                "https://yamsdev.mypinata.cloud/ipfs/"
              );
              var tokenInfo = JSON.parse(tJSON);
              var myIMG = tokenInfo.image;
              var myName = tokenInfo.name;
              //tEarn[k] = 0;
              cColumn.push(myIMG);
              cColumn.push(k);
              console.log(myName);
              cColumn.push(myName);
              console.log(cColumn);
              var owner = await nftContract.ownerOf(tokensOwned[k]);
              owner = owner.toLowerCase();
              var signedIn = _account.toLowerCase();
              tOwner[k] = owner;
              console.log(owner + ":" + tOwner[k]);
            }
            columns.push(cColumn);
          }
          rows.push(columns);
        }
        setToke({ tok: tID });
        console.log(toke);
        console.log(rows);
        setTable(false);
        setStake({ mssg: rows });
        console.log(stake);
        console.log(tOwner);
        setOwners({ massage: tOwner });
        console.log(ownes);
        //
        waiting();
      } catch (err) {
        if (err.data != null) {
          document.getElementById("demo").innerHTML = err.data.message;
        }
      }
    } else {
      console.log("Ethereum object does not exist");
    }
  };

  function Get(yourUrl) {
    var Httpreq = new XMLHttpRequest(); // a new request
    Httpreq.open("GET", yourUrl, false);
    Httpreq.send(null);
    var fixedURI = Httpreq.responseText.replace(
      "ipfs://",
      "https://yamsdev.mypinata.cloud/ipfs/"
    );
    return Httpreq.responseText;
  }

  useEffect(() => {
    checkWalletIsConnected();
    clearInterval(xt);
  }, []);

  const scrollX = window.scrollX;
  const scrollY = window.scrollY;

  useLayoutEffect(() => {
    window.scrollTo(scrollX, scrollY);
  });

  const WhichButton = (props) => {
    var propInt = parseInt(props.data);
    //console.log(propInt);
    //console.log(toke);
    var propID = toke.tok[propInt];
    //console.log(propID);
    //console.log(props.msg);
    if (ownes.massage[propInt]) {
      let boo = Boolean(
        ownes.massage[propInt] === currentAccount.toLowerCase()
      );
      if (boo == true) {
        return (
          <>
            <StakeNftButton msg={propID} />
          </>
        );
      } else {
        return (
          <>
            <TakeEarnings msg={propID} />
            <UnstakeNftButton msg={propID} />
          </>
        );
      }
    }
    return <></>;
  };

  /*const loadTokens = () => {
        if (tokensFound == false) {
            setTokensFound(true);
            getTokens();
        }
            
        return (
            <></>
        )
    };*/

  const disconnectButton = () => {
    var _text = currentAccount;
    var _len = _text.length;
    _text = _text.slice(0, 6) + "..." + _text.slice(_len - 4, _len);
    return (
      <button
        onClick={connectWalletHandler}
        className="cta-button disconnect-wallet-button"
      >
        {_text}
      </button>
    );
  };

  const Table1 = (props) => {
    return (
      <div className="tabby">
        {props.message.map((x, i) => (
          <Row key={i} data={x} message={i} />
        ))}
      </div>
    );
  };

  const Row = (props) => {
    var txt = [];
    var bgClass = [];
    for (var t = 0; t < props.data.length; t++) {
      let boo = Boolean(
        ownes.massage[props.data[t][1]] === currentAccount.toLowerCase()
      );
      if (boo == false) {
        bgClass[t] = "token-container yellow";
      } else {
        bgClass[t] = "token-container white";
      }
    }
    var _cell = [];
    let x = 0;
    for (x; x < rowSize; x++) {
      if (props.data[x][2]) {
        _cell[x] = (
          <div className="four-columns" key={props.data[x][2]}>
            <div className={bgClass[x]}>
              <div className="img-con">
                <img src={props.data[x][0]}></img>
              </div>
              {props.data[x][2]}
              <br></br>
              <WhichButton data={props.data[x][1]} />
            </div>
          </div>
        );
      } else {
        _cell[x] = (
          <div className="four-columns" key={props.data[x][2]}>
            {props.data[x][2]}
          </div>
        );
      }

      //_cell[x] = <td><div className="container"><img src={props.data[x][0]}></img> <div className="centered" id={props.data[x][1]}> {txt[x]} </div> </div><WhichButton data={props.data[x][1]} /></td>
    }

    return <>{_cell}</>;
  };

  var _cLink = "https://polygonscan.com/address/" + contractAddress;
  var _cFix = contractAddress;
  var _len = _cFix.length;
  _cFix = _cFix.slice(0, 6) + "..." + _cFix.slice(_len - 4, _len);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////
  //                                                                                             //
  //                                     RETURN TO PAGE                                          //
  //                                                                                             //
  /////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////
  window.addEventListener("resize", function(event) {
    console.log(
      document.body.clientWidth +
        " wide by " +
        document.body.clientHeight +
        " high"
    );
    if (window.innerWidth <= 700) {
      setShown(1);
    } else {
      setShown(0);
    }
  });

  const openMenu = () => {
    setMenu(true);
  };

  const closeMenu = () => {
    setMenu(false);
  };

  switch (shown) {
    default: //default
    case 0:
      return (
        <div className="main-app">
          <div className="beads">
            <div className="two-columns-c">
              <div className="two-columns">
                <div className="hero-img">
                  <img src="./Grupo_101.png"></img>
                </div>
              </div>
              <div className="two-columns two-columns-i">
                <div className="two-bunnies">
                  <div className="two-columns two-bunnies">
                    <div id="total" className="carrot-info">
                      {" "}
                      Your Staked Bunnies <h3>{staked}</h3>
                    </div>
                  </div>
                  <div className="two-columns two-bunnies">
                    <div id="wallet" className="carrot-info">
                      Your Unstaked Bunnies <h3>{unstaked}</h3>
                    </div>
                  </div>
                </div>

                <div className="stake-explain">
                  Put your bunnies to work in the hole to earn delicious
                  $CARROTS token
                </div>
                {currentAccount ? disconnectButton() : connectWalletButton()}
                <a href="">
                  <div className="cta-button go-store">GO TO STORE SOON</div>
                </a>
                <MigrateButton />
              </div>
            </div>
            <div className="bottom-buttons">
              <div className="two-columns left">
                <StakeAllButton />
                <UnstakeAllButton />
              </div>
              <div className="two-columns right">
                <ClaimAllButton />
                <div className="">
                  <img src="./Grupo 200.png" className="grupo200"></img>
                  <div className="cta-button carrot-count carrot-count-claim tooltip">
                    {earned}
                    <span className="hint">To Claim:</span>
                  </div>
                  <img src="./Grupo 200.png" className="grupo200"></img>
                  <div className="cta-button carrot-count tooltip">
                    {wallet}
                    <span className="hint">In Wallet:</span>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <Table1 message={stake.mssg} />
            </div>
            <div id="demo"></div>
            <div>
              <hr></hr>
            </div>
            <div className="footsies">
              <div id="address">
                Contract Address: <a href={_cLink}>{_cFix}</a>
              </div>
            </div>
          </div>
        </div>
      );
      break;

    case 1:
      let _menu = <></>;
      switch (menu) {
        case true:
          _menu = (
            <>
              <div className="stasta">
                <img src="./Grupo 200.png" className="grupo200"></img>
                <div className="cta-button carrot-count tooltip">
                  {earned}
                  <span className="hint">To Claim:</span>
                </div>
              </div>
              <div className="">
                {currentAccount ? disconnectButton() : connectWalletButton()}
              </div>
              <div className="">
                <div className="two-columns two-bunnies">
                  <div id="total" className="carrot-info">
                    {" "}
                    Your Staked Bunnies <h3>{staked}</h3>
                  </div>
                </div>
                <div className="two-columns two-bunnies">
                  <div id="wallet" className="carrot-info">
                    Your Unstaked Bunnies <h3>{unstaked}</h3>
                  </div>
                </div>
              </div>
              <ClaimAllButton />
              <StakeAllButton />
              <UnstakeAllButton />
            </>
          );
          break;
        case false:
          _menu = (
            <>
              <div className="crrt-info-bar">
                <img src="./Grupo 200.png" className="grupo200"></img>
                <div className="cta-button carrot-count">{earned}</div>
                <ClaimAllButton />
              </div>

              <div className="">
                <div className="two-columns two-bunnies">
                  <div id="total" className="carrot-info">
                    YOUR STAKED BUNNIES <h3>{staked}</h3>
                  </div>
                </div>
                <div className="two-columns two-bunnies">
                  <div id="wallet" className="carrot-info">
                    YOUR UNSTAKED BUNNIES <h3>{unstaked}</h3>
                  </div>
                </div>
              </div>
              <div className="mobile-buttons">
                {currentAccount ? disconnectButton() : connectWalletButton()}
                <div class="cta-button go-store">GO TO STORE SOON</div>
                <MigrateButton />
              </div>
              <div className="all-buttons">
                <StakeAllButton />
                <UnstakeAllButton />
              </div>
            </>
          );
          break;
      }
      return (
        <div className="main-app">
          {_menu}

          <div className="farmer-mob"></div>
          <div className="beads">
            <div>
              <Table1 message={stake.mssg} />
            </div>
            <div id="demo"></div>
            <div>
              <hr></hr>
            </div>
          </div>
        </div>
      );
      break;
  }
};

export default App;
